import * as React from "react";
import Layout from "../components/layout";
import "../components/css/layout_main.css";
import "../components/css/index.css";
import { Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";

const IndexPage = () => {
  return (
    <>
      <Layout pageTitle="Oriental - Home">
        <div className="hero-image">
          <Container>
            <Row className="justify-content-center">
              <p className="hero-t ">Oriental</p>
            </Row>
            <Row className="justify-content-center ">
              <p className="hero-p">Chinese food to takeaway</p>
            </Row>
            <Row className="justify-content-center link-row">
              <Col className="link-col" xs="12">
                <Link className="link-a" to="/menu">
                  View in-house menu
                </Link>
              </Col>
            </Row>
            <Row className="justify-content-center link-row">
              <Col className="link-col" xs="12">
                <a
                  className="link-a"
                  href="https://deliveroo.co.uk/menu/london/dalston/oriental-chinese-takeaway?geohash=gcpvnr0v8t45"
                >
                  Order with Deliveroo
                </a>
              </Col>
            </Row>
            <Row className="justify-content-center link-row">
              <Col className="link-col" xs="12">
                <a
                  className="link-a"
                  href="https://www.ubereats.com/gb/store/oriental/ctGfSiG5VIG7DNfDMqTD-Q?diningMode=DELIVERY"
                >
                  Order with Uber
                </a>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <p className="opening-h">Opening Hours</p>
            </Row>
            <Row className="justify-content-center opening">
              <table className="opening-table">
                <tbody>
                  <tr>
                    <td className="opening-time">Sunday:</td>
                    <td className="opening-time-hours">5pm - 10pm</td>
                  </tr>
                  <tr>
                    <td className="opening-time">Monday:</td>
                    <td className="opening-time-hours">Closed</td>
                  </tr>
                  <tr>
                    <td className="opening-time">Tuesday:</td>
                    <td className="opening-time-hours">Closed</td>
                  </tr>
                  <tr>
                    <td className="opening-time">Wednesday:</td>
                    <td className="opening-time-hours">2pm - 10pm</td>
                  </tr>
                  <tr>
                    <td className="opening-time">Thursday:</td>
                    <td className="opening-time-hours">2pm - 10pm</td>
                  </tr>
                  <tr>
                    <td className="opening-time">Friday:</td>
                    <td className="opening-time-hours">2pm - 10pm</td>
                  </tr>
                  <tr>
                    <td className="opening-time">Saturday:</td>
                    <td className="opening-time-hours">2pm - 10pm</td>
                  </tr>
                  <tr>
                    <td className="opening-time">Bank holidays:</td>
                    <td className="opening-time-hours">Closed</td>
                  </tr>
                </tbody>
              </table>
            </Row>
          </Container>
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
